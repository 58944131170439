import openSocket from "socket.io-client";
import ReconnectingWebSocket from "reconnecting-websocket";

// const socket = openSocket('http://localhost:7000');
let socketIOClient: SocketIOClient.Socket | undefined = undefined;
let websocketClient: ReconnectingWebSocket | undefined = undefined;

const getSocketIOClient = () => {
  if (!socketIOClient) {
    socketIOClient = openSocket("http://127.0.0.1:7000"); //127.0.0.1 is trusted in safari the way localhost isn't
  }
  return socketIOClient;
};

// const getWebSocketClient = (HotelCode: "AMS" | "CPH" | "RTM" | "RKV") => {
const getWebSocketClient = (HotelCode: string) => {
  const websocketAdres = `wss://creo${HotelCode.toLowerCase()}.cityhub.com/ReaderService/Reader.asmx`;
  // const websocketAdres = `ws://creo${HotelCode.toLowerCase()}.cityhub.com/ReaderService/Reader.asmx`;
  if (!websocketClient) {
    websocketClient = new ReconnectingWebSocket(
      // "wss://creocph.cityhub.com/ReaderService/Reader.asmx"
      websocketAdres
    );
  }

  if (websocketClient) {
    websocketClient.addEventListener("open", () => {
      console.log("Websocket connected");
    });
  }
  return websocketClient;
};

export { getSocketIOClient, getWebSocketClient };
